import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, Tags, MarkdownContent, MarkdownContentWrapper, MarkdownContentWrapperInner } from '../components/Section'
import { LetastArticlesBgWhite, CustomTags, SectionBeforefterBg, PageTitle, PageTitleWrapper, CardImg, TraingleShape, CaseStudsyContent, TagWrapper, CaseStudyWrapper} from "../components/CaseStudyStyle"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import LetastArticles from "../components/LetastArticles"
import BgBefore from '../images/case-study-before.png'
import BgAfter from '../images/case-study-after.png'
import CaseStudyFeatures from "../components/CaseStudyFeatures"


function caseStudyPage() {
  return (
    <Layout pageName="case-study-detail">
        <SEO title="Halifax County Health Department – Halifax, NC" description="Halifax County Health Department – Halifax, NC" />
        <SectionBeforefterBg pt="156px" pb="100px" xpt="80px" xpb="60px" mpb="40px" bgColor="#F8F8F8" bgBefore={`url(${BgBefore})`} bgAfter={`url(${BgAfter})`}>
        <TraingleShape />
        <div className="container">
            <BreadCrumb>
                <Link to="/">Home</Link> <Link to="/case-studies">Case Studies</Link><Link to="/#">Halifax County Health Department – Halifax, NC</Link>
            </BreadCrumb>
            <PageTitleWrapper>
                <PageTitle className="h2">Halifax County Health Department – Halifax, NC</PageTitle>
                <Tags to="/">Human Temperature Monitoring Technology Installation</Tags>
            </PageTitleWrapper>
            <CaseStudyWrapper>
                <CaseStudsyContent>
                    <CardImg>
                        <StaticImage
                        src="../images/case.jpg"
                        quality={100}
                        formats={["AUTO", "WEBP", "AVIF"]}
                        alt="Letast Article"
                        placeholder="blurred"
                        />
                    </CardImg>
                    <p>The Halifax County Health Department needed an effective way to record human body temperatures remotely. The solution needed to be able to provide an accurate HIPAA report, while also being able to determine whether the individual was masked. If not, a verbal reminder would activate, and would also enable remote entry point viewing through a PC or smartphone.</p>
                </CaseStudsyContent>
            </CaseStudyWrapper>
        </div>
        </SectionBeforefterBg>
        <Section pt="0" pb="100px" xpt="60px" xpb="60px" mpt="40px" mpb="40px" bgColor="#F8F8F8">
            <div className="container">
                <CaseStudyWrapper>
                    <MarkdownContentWrapper>
                    <MarkdownContentWrapperInner>
                        <MarkdownContent>
                            <h2>Services Delivered</h2>
                            <TagWrapper>
                                <CustomTags>Human Temperature Monitoring System installation & configuration</CustomTags>
                            </TagWrapper>
                            <p>We were able to provide the client with multiple remote temperature monitors distributed throughout their department facilities. The system was networked within the existing infrastructure, and is able to generate accurate temperature readings within the span of one second. The system is also programmed to alert designated representatives of any discrepancies immediately.</p>
                            <ul>
                                <li>Client is now able to receive emails and texts about any human entry which falls outside of designated temperature ranges.</li>
                                <li>Client has the ability to stop anyone from full entry to the facility in an effort to minimize the spread of infection.</li>
                                <li>The newly installed technology can generate necessary reports to meet HIPAA standards.</li>
                                <li>System provides for the ability to store data recorded for a prolonged length of time.</li>
                                <li>System is designed to provide for better traffic flow into the facility, without any need to stop for physical temperature measurements.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Problem Statements</h2>
                            <p>Remote temperature monitoring isn’t just relevant to COVID; it’s beneficial during normal cold and flu seasons, as well. The client needed a solution which promoted normal traffic flow into the building without the need for associates to have to stop clients and physically take their temperature, unless the equipment flagged a discrepancy.</p>
                            <ul>
                                <li>The clinical section of the department needed a way to capture client temperatures prior to the point of waiting room and front desk area entry.</li>
                                <li>The general staff entry area also needed its own way to be able to measure and record individual staff temperatures upon building entry.</li>
                                <li>The system needed to be able to record time-stamped, dated photos of anyone entering the facility.</li>
                                <li>System also had to be able to record individual temperatures, times, and dates for HIPAA reporting.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Wyn Approach</h2>
                            <p>The WYN approach to serving the client’s needs began with conducting a thorough site survey. With our findings in hand, we then met with the client to address specific areas of need. We also provided a full report of our findings for the benefit of subsequent internal discussions and deliberations by the client.</p>
                            <ul>
                                <li>The first point of the order was to uncover the full scope of the client’s issues regarding traffic flow into the various sections of the building.</li>
                                <li>In regards to the various points of entry, we took ambient temperatures of each area to help produce more accurate human temperature measurements.</li>
                                <li>Sunlit areas and accurate infrared measurements don’t mix well, so we identified areas of concern during peak hours and adjusted equipment settings to compensate.</li>
                                <li>It was incumbent to ensure that entering traffic flow wouldn’t be disrupted by the technology, and that devices were installed in a manner that avoided trip hazards or other accidents.</li>
                                <li>It was also necessary to network devices so the client could receive real-time alerts via PC or smartphone.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Challenges</h2>
                            <p>The WYN team was faced with three unique challenges to overcome for this project: determining human traffic flow patterns for walk-ins and appointment-only patients, meeting HIPAA operating guidelines with COVID restrictions, and being able to capture accurate human temperature readings in the face of widely variable ambient temperature conditions.</p>
                            <ul>
                                <li>Determined traffic flow patterns during both high-volume and minimal visitation scenarios.</li>
                                <li>Provided capability for delivering ongoing reports needed for HIPAA requirements.</li>
                                <li>Took room temperature readings in different sunlight and ambient temperature conditions, in order to properly calibrate instruments for reading human temperatures.</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <h2>Benefits</h2>
                            <p>This remote temperature monitoring technology offers many benefits. The system automatically generates temperature readings for anyone that walks within range. The client can visually monitor all points of entry by PC or smartphone, and thus better manage human intake traffic flow. In addition, all hardware elements are networked to a central system which generates email/text alerts for any temperature discrepancies.</p>
                            <ul>
                                <li>Accurate human temperature measurement in diverse ambient conditions</li>
                                <li>No disruption to traffic flow</li>
                                <li>Real-time temperature data and alerts complete with photo, timestamp, and date</li>
                                <li>Automatic no-mask detection with a verbal reminder</li>
                                <li>System is fully networked and Wi-Fi capable</li>
                            </ul>
                        </MarkdownContent>
                        <MarkdownContent>
                            <CaseStudyFeatures />
                        </MarkdownContent>
                    </MarkdownContentWrapperInner>
                    </MarkdownContentWrapper>
                </CaseStudyWrapper>
            </div>
        </Section>
        <LetastArticlesBgWhite>
         <LetastArticles />
        </LetastArticlesBgWhite>
    </Layout>
  )
}
export default caseStudyPage